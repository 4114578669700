<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">
        Tambah Pemeriksaan Laboratorium
      </h5>
      <h6 style="font-weight: 500" class="mt-5">Pemeriksaan</h6>
      <div class="row">
        <div class="col-4 col-sm-12 my-4">
          <p>Kategori Laboratorium <span style="color: #dc3545">*</span></p>
          <a-radio-group
            :options="categoryOptions"
            v-model="masterExamination.categoryId"
          />
        </div>
        <div class="col-12 mt-4 mb-3">
          <p>Kode LOINC*</p>
          <div class="row px-3">
            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between col-12 col-sm-6 col-md-4"
              @click="handleModal('master')"
            >
              <span>{{
                masterExamination.loincCode
                  ? masterExamination.loincCode
                  : 'Cari LOINC'
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
          </div>
        </div>
        <div class="col-12 mt-4">
          <p>Nama Pemeriksaan*</p>
          <div class="row px-3">
            <a-input
              placeholder="Nama Pemeriksaan"
              class="col-12 col-sm-6 col-md-4"
              v-model="masterExamination.name"
            ></a-input>
          </div>
        </div>
      </div>

      <a-divider></a-divider>
      <h6>Komponen Pemeriksaan</h6>
      <p>
        Anda perlu menambahkan setidaknya satu komponen pemeriksaan ke dalam
        pemeriksaan ini. Komponen pemeriksaan dengan tipe hasil kuantitatif
        dapat memiliki satu atau lebih indikator, sementara komponen pemeriksaan
        dengan tipe hasil nominal dan ordinal memiliki nilai hasil berdasarkan
        standar LOINC.
      </p>
      <div v-for="(item, idx) in components" :key="idx" class="card p-3">
        <div class="row">
          <!-- INI COMPONENT ROW -->
          <div class="col-12 my-2 my-xl-0 col-xl-3">
            <p>Kode LOINC*</p>
            <a-button
              type="secondary"
              class="w-100 d-flex align-items-center justify-content-between"
              @click="handleModal('component', idx)"
            >
              <span>{{ item.codeLoinc ? item.codeLoinc : 'Cari LOINC' }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
          </div>
          <div class="col-12 my-2 my-xl-0 col-xl-3">
            <p>Nama Komponen Pemeriksaan*</p>
            <a-input
              placeholder="Nama Komponen Pemeriksaan"
              v-model="item.name"
            ></a-input>
          </div>

          <div class="col-12 my-2 my-xl-0 col-xl-2">
            <p>Tipe Hasil*</p>

            <a-select
              ref="select"
              v-model="item.resultType"
              style="width: 100%"
              disabled
            >
              <a-select-option value="" selected>Tipe Hasil</a-select-option>
              <a-select-option value="Quantitative"
                >Quantitative</a-select-option
              >
              <a-select-option value="Nominal">Nominal</a-select-option>
              <a-select-option value="Ordinal">Ordinal</a-select-option>
              <a-select-option value="Narrative">Narrative</a-select-option>
            </a-select>
          </div>
          <div class="col-12 my-2 my-xl-0 col-xl-2">
            <p>Satuan Ukuran*</p>
            <a-input placeholder="Satuan Ukuran" v-model="item.unit"></a-input>
          </div>
          <div
            class="col-12 mt-4 mb-2 d-flex align-items-center justify-content-center d-flex d-xl-none"
          >
            <div class="row">
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-down.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="components.length > 1 && idx !== components.length - 1"
                  @click="handleData('component', 'down', idx)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-up.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="components.length > 1 && idx"
                  @click="handleData('component', 'up', idx)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/x.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="handleData('component', 'delete', idx)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="item.resultType === 'Ordinal' || item.resultType === 'Nominal'"
        >
          <div class="row mt-2">
            <div class="col-4">
              <p>Nilai Hasil dari LOINC</p>
              <a-input
                v-for="(answer, idx) in item.loincAnswerList"
                :key="idx"
                v-model="answer['Answer List ']"
                disabled
                class="my-2"
                >{{ answer.Code }}</a-input
              >
            </div>

            <div class="col-4">
              <p>Tampilan Nilai Hasil</p>
              <a-input
                v-for="(answer, idx) in item.answerList"
                :key="idx"
                v-model="answer['Answer List ']"
                class="my-2"
              ></a-input>
            </div>
          </div>
        </div>
        <div
          v-if="
            item.resultType === 'Quantitative' ||
            item.resultType === 'Ordinal' ||
            item.resultType === 'Nominal'
          "
        >
          <div
            class="row mt-4"
            style="position: relative"
            v-for="(indicator, indexIndicator) in item.indicators"
            :key="indexIndicator"
          >
            <!-- INI INDICATOR ROW -->
            <div class="col-12 my-2 col-xl-2">
              <p v-if="!indexIndicator">Nama Indikator*</p>
              <a-input
                placeholder="Cth: Laki-laki Dewasa"
                v-model="indicator.name"
              ></a-input>
            </div>
            <div class="col-12 my-2 col-xl-1 px-xl-0 ml-xl-2">
              <p v-if="!indexIndicator">Jenis Kelamin*</p>
              <a-radio-group
                :options="genderOptions"
                v-model="indicator.gender"
                class="py-xl-2"
              />
            </div>
            <div class="col-12 my-2 col-xl-3">
              <p v-if="!indexIndicator">Rentang Umur*</p>
              <div class="row">
                <div class="col-12 col-xl-5 pr-0">
                  <div class="row">
                    <div class="col-3 col-xl-4 pr-xl-0">
                      <a-input
                        type="number"
                        placeholder=""
                        class="pr-xl-1"
                        v-model="indicator.minAge"
                      ></a-input>
                    </div>
                    <div class="col-9 col-xl-8">
                      <a-select
                        v-model="indicator.minAgeType"
                        class="w-100"
                        :options="dateOptions"
                      ></a-select>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 my-2 col-xl-2 p-0 d-flex align-items-center justify-content-center"
                >
                  s/d
                </div>
                <div class="col-12 col-xl-5 pr-0">
                  <div class="row">
                    <div class="col-3 col-xl-4 pr-xl-0">
                      <a-input
                        type="number"
                        placeholder=""
                        class="pr-xl-1"
                        v-model="indicator.maxAge"
                      ></a-input>
                    </div>
                    <div class="col-9 col-xl-8">
                      <a-select
                        v-model="indicator.maxAgeType"
                        class="w-100"
                        :options="dateOptions"
                      ></a-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 my-2 col-xl-5">
              <div class="row">
                <div class="col-xl-3">
                  <p v-if="!indexIndicator">Nilai Rujukan*</p>
                  <a-input
                    placeholder="Cth: 1-10"
                    v-model="indicator.referenceValue"
                  ></a-input>
                </div>
                <div class="col-xl-4">
                  <p v-if="!indexIndicator">Nilai Kritis Rendah*</p>
                  <a-input
                    placeholder="Cth: <1"
                    v-model="indicator.criticalLow"
                  ></a-input>
                </div>
                <div class="col-xl-4">
                  <p v-if="!indexIndicator">Nilai Kritis Tinggi*</p>
                  <a-input
                    placeholder="Cth: >1"
                    v-model="indicator.criticalHigh"
                  ></a-input>
                </div>
              </div>
            </div>
            <!-- ARROW2an -->
            <div
              style="
                position: absolute;
                right: 30px;
                bottom: 12px;
                width: 140px;
                height: 30px;
              "
              class="row"
            >
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-down.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="
                    item.indicators.length > 1 &&
                    indexIndicator !== item.indicators.length - 1
                  "
                  @click="handleData('indicator', 'down', idx, indexIndicator)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-up.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="item.indicators.length > 1 && indexIndicator"
                  @click="handleData('indicator', 'up', idx, indexIndicator)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/x.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="
                    handleData('indicator', 'delete', idx, indexIndicator)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="my-3 d-flex align-items-center"
          @click="addIndicator(idx)"
          v-if="item.resultType === 'Quantitative'"
        >
          <img
            src="resources/images/plus-icon.svg"
            alt="Icon"
            style="height: 16px; width: 16px; cursor: pointer"
          />
          <span style="color: #428a94; margin-left: 5px; cursor: pointer"
            >Tambah Indikator</span
          >
        </div>
        <!-- ARROW2an -->
        <div
          style="
            position: absolute;
            top: 52px;
            right: 30px;
            bottom: 17px;
            width: 140px;
            height: 30px;
          "
          class="row d-none d-xl-flex"
        >
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-down.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx !== components.length - 1"
              @click="handleData('component', 'down', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-up.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx"
              @click="handleData('component', 'up', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/x.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              @click="handleData('component', 'delete', idx)"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div
          type="primary"
          class="d-flex align-items-center mb-4"
          style="width: 230px"
          @click="addComponents"
        >
          <img
            src="resources/images/plus-icon.svg"
            alt="Icon"
            style="height: 16px; width: 16px; cursor: pointer"
          />
          <span style="color: #428a94; margin-left: 5px; cursor: pointer"
            >Tambah Komponen Pemeriksaan</span
          >
        </div>
        <a-button
          type="primary"
          class="mt-2 d-flex flex-row align-items-center justify-content-center"
          style="width: 230px"
          @click="submitData"
        >
          <img
            src="resources/images/save.png"
            alt="Icon"
            style="height: 18px; width: 18px"
          />
          <span style="margin-left: 5px">Simpan Pemeriksaan</span></a-button
        >
      </div>
    </div>
    <a-modal
      v-model="modalVisible"
      title="LOINC Permintaan Pemeriksaan Lab Patologi Kinis"
      centered
      width="1400px"
      :footer="null"
    >
      <div>
        <p>Kode LOINC*</p>
        <a-input-search
          placeholder="Ketik nama pemeriksaan untuk mencari..."
          style="width: 350px"
          @change="searchKodeLoinc"
          class="mb-4"
        />
      </div>
      <a-table
        :dataSource="filteredLoincCode"
        :columns="loincCodeColumns"
        @rowClick="handleRowClick"
        :rowKey="(record) => record.No"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
  getLoincCodeAndAnswer,
  createExamination,
} from '@/services/axios/api/pemeriksaan'
import { notification } from 'ant-design-vue'

export default {
  data: function () {
    const columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'no',
        width: '50px',
      },
      {
        title: 'Kategori Lab',
        dataIndex: 'ExaminationCategory.name',
        key: 'ExaminationCategory.name',
        width: '200px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'name',
        key: 'id',
      },
    ]
    const loincCodeColumns = [
      {
        title: 'Kode LOINC',
        dataIndex: 'Code',
        key: 'Code',
        width: '120px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'Nama Pemeriksaan',
        key: 'Nama Pemeriksaan',
        width: '300px',
      },
      {
        title: 'Permintaan/Hasil',
        dataIndex: 'Permintaan/Hasil',
        key: 'Permintaan/Hasil',
        width: '200px',
      },
      {
        title: 'Spesimen',
        dataIndex: 'Spesimen',
        key: 'Spesimen',
        width: '150px',
      },
      {
        title: 'Tipe Hasil',
        dataIndex: 'Tipe hasil pemeriksaan',
        key: 'Tipe Hasil Pemeriksaan',
        width: '110px',
      },
      {
        title: 'Satuan',
        dataIndex: 'Satuan',
        key: 'Satuan',
        width: '100px',
      },
      {
        title: 'Metode Analisis',
        dataIndex: 'Metode Analisis',
        key: 'Metode Analisis',
        width: '120px',
      },
    ]
    return {
      example: [],
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns,
      categories: [],
      selectedCategory: '',
      selectedGender: '',
      components: [
        {
          name: '',
          codeLoinc: '',
          resultType: '',
          unit: '',
          indicators: [],
        },
      ],
      categoryOptions: [
        {
          label: 'Apple',
          value: 'Apple',
        },
        {
          label: 'Pear',
          value: 'Pear',
        },
        {
          label: 'Orange',
          value: 'Orange',
        },
      ],
      genderOptions: [
        {
          label: 'L',
          value: 'L',
        },
        {
          label: 'P',
          value: 'P',
        },
      ],
      dateOptions: [
        {
          label: 'Month',
          value: 'M',
        },
        {
          label: 'Year',
          value: 'Y',
        },
      ],
      selectedCategoryLab: '',
      loincCode: [],
      filteredLoincCode: [],
      loincAnswer: [],
      filteredLoincAnswer: [],
      modalVisible: false,
      loincCodeColumns,
      masterExamination: {
        name: '',
        categoryId: '',
        loincCode: '',
      },
      loincType: '',
      selectedComponentId: '',
      loincAnswerList: [],
      answerList: [],
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    async fetchData() {
      const { data } = await getExaminations()
      const { data: dataCategories } = await getExaminationsCategories()
      const { data: dataLoinc } = await getLoincCodeAndAnswer()
      this.loincCode = dataLoinc.data.LoincCode
      this.filteredLoincCode = dataLoinc.data.LoincCode
      this.loincAnswer = dataLoinc.data.LoincAnswer
      this.filteredLoincAnswer = dataLoinc.data.LoincAnswer

      // console.log(this.loincCode)
      // console.log(dataLoinc.data.LoincCode, 'iniloinc')
      this.categories = dataCategories.categories
      // console.log(dataCategories, 'inicategories')
      this.categoryOptions = dataCategories.categories.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      const dataTable = data.pemeriksaan.map((e, idx) => {
        return {
          ...e,
          key: idx + 1,
        }
      })
      this.dataSource = dataTable
      this.filteredDataSource = dataTable
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase() // Convert to lowercase for case-insensitive matching
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(e) {
      if (e) {
        this.filteredDataSource = this.dataSource.filter(
          (item) => item.ExaminationCategory.name === e,
        )
      }
    },
    addComponents() {
      const component = {
        name: '',
        codeLoinc: '',
        resultType: '',
        unit: '',
        indicators: [],
      }
      this.components.push(component)
    },
    addIndicator(index) {
      const indicator = {
        name: '',
        gender: '',
        minAge: '0',
        minAgeType: 'M/Y',
        maxAge: '0',
        maxAgeType: 'M/Y',
        referenceValue: '',
        criticalLow: '',
        criticalHigh: '',
      }
      this.components[index].indicators.push(indicator)
    },
    handleModal(type, idx) {
      if (type === 'master') {
        this.loincType = 'master'
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'] !== 'Hasil',
        )
      } else if (type === 'component') {
        this.loincType = 'component'
        this.selectedComponentId = idx
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'] !== 'Permintaan',
        )
      }
      this.modalVisible = !this.modalVisible
    },
    async submitData() {
      console.log(this.masterExamination, 'inisubmit')
      console.log(this.components, 'inisubmit')
      const { data } = await createExamination(
        this.masterExamination,
        this.components,
      )
      console.log(data)
      if (data.type === 'success') {
        notification.success({
          message: data.message,
          description: '',
          duration: 5,
        })
        this.$router.push('/laborant')
      }
    },
    searchKodeLoinc(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredLoincCode = this.loincCode.filter((item) =>
        item['Nama Pemeriksaan'].toLowerCase().includes(filterValue),
      )
    },
    handleRowClick(record) {
      // console.log(record)
      if (this.loincType === 'master') {
        this.masterExamination.name = record['Nama Pemeriksaan']
        this.masterExamination.loincCode = record.Code
      } else if (this.loincType === 'component') {
        this.components[this.selectedComponentId].indicators = []
        this.components[this.selectedComponentId].answerList = []
        this.components[this.selectedComponentId].loincAnswerList = []
        this.components[this.selectedComponentId].name =
          record['Nama Pemeriksaan']
        this.components[this.selectedComponentId].resultType =
          record['Tipe hasil pemeriksaan']
        this.components[this.selectedComponentId].codeLoinc = record.Code
        if (record.Satuan) {
          this.components[this.selectedComponentId].unit = record.Satuan
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Quantitative' ||
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          if (!this.components[this.selectedComponentId].indicators.length) {
            this.addIndicator(this.selectedComponentId)
          }
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          const filteredData = this.loincAnswer.filter(
            (e) =>
              e['LOINC Code'] ===
              this.components[this.selectedComponentId].codeLoinc,
          )
          console.log(filteredData, 'inifilteranswer')
          this.components[
            this.selectedComponentId
          ].loincAnswerList = filteredData
          this.components[this.selectedComponentId].answerList = filteredData
        }
      }
      this.modalVisible = !this.modalVisible
      // this.$router.push('/')
    },
    handleData(dataType, actionType, index, indexIndicator) {
      if (dataType === 'component') {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.components.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index + 1])
            this.$set(this.components, index + 1, temp)
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index - 1])
            this.$set(this.components, index - 1, temp)
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components.splice(index, 1)
        }
      } else {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (indexIndicator < this.components[index].indicators.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator + 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator + 1,
              temp,
            )
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (indexIndicator > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator - 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator - 1,
              temp,
            )
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components[index].indicators.splice(indexIndicator, 1)
        }
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
